
import { Component, Prop, Vue, Emit, Inject, Watch } from "vue-property-decorator"
import {
  CreateScopeLibraryPhaseMutation,
  CreateScopeLibraryPhaseMutationMutation,
  ScopeLibraryPhase,
  UpdateScopePhaseSubscriptionMutationMutation,
  UpdateScopePhaseSubscriptionMutation,
} from "@/gql"
import { ScopePhaseType } from "@/components/scopeLibrary/PhaseList.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

const INPUT_LENGTH_BREAKPOINT = 20

@Component({
  components: {},
})
export default class PhaseInput extends Vue {
  @Prop({ required: true }) value!: string
  @Prop() refetchQueries!: RefetchQueryDescription
  @Prop({ required: true }) scopeServiceId!: BigInt
  @Prop() phase!: ScopePhaseType
  @Prop({ default: true }) readonly shouldSaveChanges?: boolean
  @Prop({ default: true }) readonly isEditable?: boolean

  form = {
    name: "",
  }

  @Inject() readonly allPhases!: () => ScopeLibraryPhase[]

  inputError = false

  get allExistingPhases() {
    return this.allPhases()
  }

  get width() {
    return this.form.name.trim().length > INPUT_LENGTH_BREAKPOINT
      ? `${this.form.name.trim().length}ch`
      : "200px"
  }

  @Emit("saved")
  emitSaved() {
    return true
  }

  @Emit("saving")
  emitSaving() {
    return true
  }

  @Watch("phase", { immediate: true })
  onPhasePropChange() {
    if (this.phase.name) {
      this.form.name = this.phase.name
    }
  }

  onPhaseChange(phase: ScopePhaseType) {
    this.$emit("input", this.form.name)
    if (!this.form.name) return (this.inputError = true)

    if (this.shouldSaveChanges) {
      if (phase.id) this.updatePhase(phase)
      else this.createPhase(phase)
    }
  }

  async createPhase(phase: ScopePhaseType) {
    this.emitSaving()

    const { name } = this.form

    const result = await this.mutate<CreateScopeLibraryPhaseMutationMutation>({
      mutation: CreateScopeLibraryPhaseMutation,
      variables: {
        name,
        scopeServiceId: this.scopeServiceId,
      },
      refetchQueries: this.refetchQueries,
    })

    if (result.data?.createScopeLibraryPhase?.scopePhaseSubscription) {
      this.$emit("saved", {
        tempId: phase.tempId,
        id: result.data?.createScopeLibraryPhase?.scopePhaseSubscription.phase.id,
      })
    } else if (result.data?.createScopeLibraryPhase?.error) {
      this.$emit("error")
    }
  }

  async updatePhase(phase: ScopePhaseType) {
    this.emitSaving()
    const { name } = this.form

    await this.mutate<UpdateScopePhaseSubscriptionMutationMutation>({
      mutation: UpdateScopePhaseSubscriptionMutation,
      variables: {
        libraryPhaseName: name,
        id: phase.phaseSubscriptionId,
      },
      error: () => {
        this.$emit("error")
      },

      update: (cache, { data }) => {
        if (data?.updateScopePhaseSubscription?.error) {
          this.$emit("error")
          return
        }
        this.emitSaved()
      },
    })
  }

  mounted() {
    if (!this.phase.name?.length) {
      ;(this.$refs.input as HTMLElement).focus()
    }
  }
}
